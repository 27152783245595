import * as React from 'react';
import Chip from '@mui/material/Chip';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import TuneIcon from '@mui/icons-material/Tune';
import IconButton from '@mui/material/IconButton';
import Style from '../Styles/FiltersListStyle'
import {
  Link
} from "react-router-dom";

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 0,
    top: 0,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

export default function AvatarChips(props) {
  const classes = Style()
  return (
    <Link to={'/subscribers/'+ props.item.subscribersID} style={classes}>
      <Chip style={classes.filterChip} avatar={ <IconButton aria-label="cart">
        <TuneIcon />
    </IconButton>} label={props.item.subscribersKeyword} />
    </Link>
  );
}