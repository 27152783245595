import React from 'react';
import ReactDOM from 'react-dom';
import logo from './logo.svg';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import RouterComponent from './Sites/www_zootzle_com/Router/RouterComponent'
function App() {
  return (
   <RouterComponent />
  );
}

export default App;
