import color from '@mui/material/colors/green';
export default function Style() {
    const style = {
        root: {
            maxWidth: 750,
            margin: '0 auto'
        },
        pageContainer: {
            marginTop: 75
        },
        rootPaper: {
            maxWidth: 750,
            padding: 8,
            margin: '0 auto'
        },
        formFullWidth: {
            width: '100%',
            height: 'auto',
            marginBottom: 8
        },
        title: {
            width: "100%",
            height: "auto",
            textAlign: "center"
        },
        formControl: {
            width: '30%',
        },
        formControlRadioGroup: {
            width: 'auto',
            margin: 0,
            display:'inline-block'
        },
        formRadioGroup: {
            flexDirection: 'row'
        },
        formControlSelect: {
            width: '25%',
            margin: '0 8px',
            height: "auto"
        },
        radioGroupFormControlLabel: {
            width: '30%'
        },
        closeButton: {
            width: "100%",
            height:"auto",
            marginBottom: 8
        },
        proceedToCheckout: {
            textDecoration: 'none',
            display: "block",
            margin: "0 5px",
            width: "100%",
            color: 'inherit'
          },
          termsAndConditionsLink: {
                color: color[500],
                textDecoration: "none"
          },
          yourOrdersButton: {
            width: "100%",
            height:"auto",
            marginBottom: 8,
            marginTop: 8,
            textDecoration: 'none',
          }
      }
    return style
}