export default function Style(theme) {
  const style = {
    root: {
      marginTop: "0 auto"
    },
    progress: {
      margin: "0 auto"
    }
  }
    return style
}