import React from 'react';
import Style from '../Styles/YourAccountPaperStyle';
import Button from '@mui/material/Button';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import CircularProgress from '@mui/material/CircularProgress';
import settings from '../../../Settings/Settings';
import {
    Link
  } from "react-router-dom";


export default function YourAccountPaper(props) {
  const classes = Style();

    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }
    
    if (!componentMethods.getLocalStorage('user'))
        return null;

    const [yourAccountUsersFirstName, setYourAccountUsersFirstName] = React.useState(componentMethods.getLocalStorage('user').usersFirstName);
    const [yourAccountUsersLastName, setYourAccountUsersLastName] = React.useState(componentMethods.getLocalStorage('user').usersLastName);
    const [yourAccountUsersEmail, setYourAccountUsersEmail] = React.useState(componentMethods.getLocalStorage('user').usersEmail);
    const [yourAccountUsersOldPassword, setYourAccountUsersOldPassword] = React.useState();
    const [yourAccountUsersNewPassword, setYourAccountUsersNewPassword] = React.useState();
    const [yourAccountUsersNewPasswordConfirm, setYourAccountUsersNewPasswordConfirm] = React.useState();
    const [yourAccountUsersDateOfBirthYear, setYourAccountUsersDateOfBirthYear] = React.useState(componentMethods.getLocalStorage('user').usersDateOfBirth.split('-')[0]);
    const [yourAccountUsersDateOfBirthMonth, setYourAccountUsersDateOfBirthMonth] = React.useState(componentMethods.getLocalStorage('user').usersDateOfBirth.split('-')[1]);
    const [yourAccountUsersDateOfBirthDay, setYourAccountUsersDateOfBirthDay] = React.useState(componentMethods.getLocalStorage('user').usersDateOfBirth.split('-')[2]);
    const [yourAccountUsersSexID, setYourAccountUsersSexID] = React.useState(componentMethods.getLocalStorage('user').usersUsersSexID);
    const updateAccountAction = () => {
        if(yourAccountUsersNewPassword && yourAccountUsersNewPassword !== yourAccountUsersNewPasswordConfirm) {
            componentMethods.addError(componentMethods.getManagerState().language["LANG_ERROR_INVALID_PASSWORD_CONFIRM"], {formInvalidCreateAccountPassword: true, formInvalidCreateAccountPasswordConfirm: true, loadingYourAccount: false})
        } else {
            componentMethods.setManagerState({loadingYourAccount: true})
            let requestUpdateAccountObject = {
                usersEmail: yourAccountUsersEmail,
                usersLastName: yourAccountUsersLastName,
                usersFirstName: yourAccountUsersFirstName,
                usersUsersSexID: yourAccountUsersSexID,
                usersDateOfBirth: yourAccountUsersDateOfBirthYear + '-' + yourAccountUsersDateOfBirthMonth + '-' + yourAccountUsersDateOfBirthDay
            }
            if(yourAccountUsersNewPassword && yourAccountUsersNewPassword === yourAccountUsersNewPasswordConfirm) {
                requestUpdateAccountObject.usersPasswordCheck = yourAccountUsersOldPassword
                requestUpdateAccountObject.usersPassword = yourAccountUsersNewPassword
            }

            componentMethods.ajax(settings.API_URL + settings.API_ENDPOINT_UPDATE_PROFILE + '?accountToken=' + componentMethods.getLocalStorage("user").tokensHash,requestUpdateAccountObject,'PUT',null, (data) => {componentMethods.setYourAccountData(data)}, {}, false, (data) => {componentMethods.setUpdateAccountErrors(data)})
        }
    }
  return (
    <form style={classes.root} noValidate autoComplete="off">
      <Typography variant="h5" component="h5" style={classes.title}>
            {componentConstants.language.LANG_YOUR_ACCOUNT}
        </Typography>
        <TextField value={yourAccountUsersFirstName} error={componentMethods.getManagerState().formInvalidYourAccountUsersFirstName} style={classes.formFullWidth} onChange={(event) => {setYourAccountUsersFirstName(event.target.value)}} id="YOUR_ACCOUNT_FIRST_NAME" label={componentConstants.language.LANG_FIRST_NAME} fullWidth={true} />
        <TextField value={yourAccountUsersLastName} error={componentMethods.getManagerState().formInvalidYourAccountUsersLastName} style={classes.formFullWidth} onChange={(event) => {setYourAccountUsersLastName(event.target.value)}} id="YOUR_ACCOUNT_LAST_NAME" label={componentConstants.language.LANG_LAST_NAME} fullWidth={true} />
        <TextField disabled={true} value={yourAccountUsersEmail} error={componentMethods.getManagerState().formInvalidYourAccountUsersEmail} style={classes.formFullWidth} onChange={(event) => {setYourAccountUsersEmail(event.target.value)}} id="YOUR_ACCOUNT_EMAIL" label={componentConstants.language.LANG_EMAIL} fullWidth={true} />
       {componentMethods.getManagerState().showYourAccountChangePassword ?
        <React.Fragment>
            <TextField value={yourAccountUsersOldPassword} type="password" error={componentMethods.getManagerState().formInvalidYourAccountOldPassword} style={classes.formFullWidth} onChange={(event) => {setYourAccountUsersOldPassword(event.target.value)}} id="YOUR_ACCOUNT_OLD_PASSWORD" label={componentConstants.language.LANG_OLD_PASSWORD} fullWidth={true} />
            <TextField value={yourAccountUsersNewPassword} type="password" error={componentMethods.getManagerState().formInvalidYourAccountNewPassword} style={classes.formFullWidth} onChange={(event) => {setYourAccountUsersNewPassword(event.target.value)}} id="YOUR_ACCOUNT_NEW_PASSWORD" label={componentConstants.language.LANG_NEW_PASSWORD} fullWidth={true} />
            <TextField value={yourAccountUsersNewPasswordConfirm} type="password" error={componentMethods.getManagerState().formInvalidYourAccountNewPasswordConfirm} style={classes.formFullWidth} onChange={(event) => {setYourAccountUsersNewPasswordConfirm(event.target.value)}} id="YOUR_ACCOUNT_NEW_CONFIRM_PASSWORD" label={componentConstants.language.LANG_NEW_PASSWORD_CONFIRM} fullWidth={true} />
            <Button onClick={() =>{componentMethods.setManagerState({
                showYourAccountChangePassword: false, 
                });
                setYourAccountUsersOldPassword(null);
                setYourAccountUsersNewPassword(null);
                setYourAccountUsersNewPasswordConfirm(null);
                }} variant="outlined" disableElevation color="secondary" fullWidth={true} style={classes.closeButton}>
            {componentConstants.language.LANG_CANCEL_CHANGE_PASSWORD}
        </Button>
        </React.Fragment>
        :
        <Button onClick={() =>{componentMethods.setManagerState({showYourAccountChangePassword: true})}} variant="contained" disableElevation color="primary" fullWidth={true} style={classes.closeButton}>
            {componentConstants.language.LANG_CHANGE_PASSWORD}
        </Button>
       } 
        <FormControl style={classes.formControlSelect}>
            <InputLabel id="YOUR_ACCOUNT_BIRTH_DAY_INPUT_LABEL">{componentConstants.language.LANG_DATE_OF_BIRTH_DAY}</InputLabel>
            <Select
             error={componentMethods.getManagerState().formInvalidYourAccountUsersDateOfBirth}
            value={yourAccountUsersDateOfBirthDay}
             onChange={(event) => {setYourAccountUsersDateOfBirthDay(event.target.value)}}
            labelId="YOUR_ACCOUNT_BIRTH_DAY_LABEL"
            id="YOUR_ACCOUNT_BIRTH_DAY"
            >
           {['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'].map((element, index) => { 
                return <MenuItem key={index} value={element}>{element}</MenuItem>
            })}
            </Select>
        <FormHelperText>{componentConstants.language.LANG_DATE_OF_BIRTH}</FormHelperText>
      </FormControl>
      <FormControl style={classes.formControlSelect}>
            <InputLabel id="YOUR_ACCOUNT_BIRTH_MONTH_INPUT_LABEL">{componentConstants.language.LANG_DATE_OF_BIRTH_MONTH}</InputLabel>
            <Select
            error={componentMethods.getManagerState().formInvalidYourAccountUsersDateOfBirth}
            value={yourAccountUsersDateOfBirthMonth}
            onChange={(event) => {setYourAccountUsersDateOfBirthMonth(event.target.value)}}
            labelId="YOUR_ACCOUNT_BIRTH_MONTH_LABEL"
            id="YOUR_ACCOUNT_BIRTH_MONTH_LABEL"
            >
            {['01','02','03','04','05','06','07','08','09','10','11','12'].map((element, index) => { 
                return <MenuItem key={index} value={element}>{element}</MenuItem>
            })}
            </Select>
        <FormHelperText>{componentConstants.language.LANG_DATE_OF_BIRTH}</FormHelperText>
      </FormControl>
      <FormControl style={classes.formControlSelect}>
            <InputLabel id="YOUR_ACCOUNT_BIRTH_YEAR_INPUT_LABEL">{componentConstants.language.LANG_DATE_OF_BIRTH_YEAR}</InputLabel>
            <Select
            error={componentMethods.getManagerState().formInvalidYourAccountUsersDateOfBirth}
            value={yourAccountUsersDateOfBirthYear}
            labelId="YOUR_ACCOUNT_BIRTH_YEAR_LABEL"
            id="YOUR_ACCOUNT_BIRTH_YEAR"
            onChange={(event) => {setYourAccountUsersDateOfBirthYear(event.target.value)}}
            >
                {componentMethods.getManagerState().createAccountYears.items.map((element, index) => { 
                        return <MenuItem key={index} value={element.year}>{element.year}</MenuItem>
                     })}
            </Select>
        <FormHelperText>{componentConstants.language.LANG_DATE_OF_BIRTH}</FormHelperText>
      </FormControl>
      <FormControl  error={componentMethods.getManagerState().formInvalidYourAccountUsersUsersSexID} component="fieldset" style={classes.formFullWidth}>
        <FormLabel  style={classes.formFullWidth} component="legend">{componentConstants.language.LANG_SEX}</FormLabel>
            <RadioGroup aria-label="gender" name="usersUsersSexID"  style={classes.formRadioGroup} value={yourAccountUsersSexID} onChange={(event) => {setYourAccountUsersSexID(event.target.value)}} >
                <FormControlLabel style={classes.formControlRadioGroup} value={"2"} control={<Radio />} label={componentConstants.language.LANG_FEMALE} />
                <FormControlLabel style={classes.formControlRadioGroup} value={"1"} control={<Radio />} label={componentConstants.language.LANG_MALE} />
                <FormControlLabel style={classes.formControlRadioGroup} value={"3"} control={<Radio />} label={componentConstants.language.LANG_OTHER} />
            </RadioGroup>
        </FormControl>
        {
        componentMethods.getManagerState().loadingYourAccount ?
        <CircularProgress />
        :
        <Button onClick={updateAccountAction} variant="contained" disableElevation color="secondary" fullWidth={true} style={classes.closeButton}>
            {componentConstants.language.LANG_UPDATE_ACCOUNT}
        </Button>
        }
        {
        componentMethods.getManagerState().loadingYourAccount ?
        null
        :
        <React.Fragment>  
            <Button startIcon={<ExitToAppIcon />} onClick={componentMethods.logoutUser} variant="contained" color="secondary" fullWidth={true} style={classes.closeButton}>
                {componentConstants.language.LANG_LOGOUT}
            </Button>        
        </React.Fragment>
        }
    </form>
  );
}