export default function Style(theme) {
  const style = {
    root: {
    },
    pageContainer: {
      marginTop: 75
    },
    commentPager: {
      padding: 8,
      margin: '8px 0 8px 0',
      maxWidth: 350,
      margin: '8px auto',
      display: "block"
    },
  }
    return style
}