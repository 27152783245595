import React, { useEffect } from 'react';
import Style from '../Styles/NewsFeedStyle'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import IconButton from '@mui/material/IconButton';

export default function Comments(props) {
    const classes = Style();
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }

  return (
    <React.Fragment>
        <IconButton onClick={() => {componentMethods.toggleShowComments(); componentMethods.getComments()}}><ChatBubbleOutlineIcon /></IconButton>
    </React.Fragment>
  );
}