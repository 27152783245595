export default function Style(theme) {
  const style = {
    root: {
    },
    pageContainer: {
      marginTop: 75
    },
    filterChip: {
      margin: 8
    }
  }
    return style
}